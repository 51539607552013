.hamburger-button {
  padding: 4px;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 4px;
  background-color: var(--primary);
  transition: all var(--duration) ease-in-out;
}

.button-cookie {
  display: flex;
  padding: 8px;
  border-radius: 50%;
  color: var(--primary);
  background-color: var(--white);
  border: none;
  outline: none;

  z-index: 999;

  transition: background-color var(--duration);
  cursor: pointer;
}
@media (min-width: 769px) {
  .button-cookie:focus {
    color: var(--white);
    background-color: var(--primary);
  }
}
