ol {
  counter-reset: section;
}

ol > li {
  list-style-type: none;
}

ol > li > ol {
  counter-reset: subsection;
}

ol > li > ol > li {
  counter-increment: subsection;
  list-style-type: none;
}

ol > li > ol > li::before {
  content: counters(section, ".") "." counter(subsection) " ";
  margin-right: 5px;
}
