* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

html {
  background-color: var(--blue-light-7);
  scroll-behavior: smooth;
}

:root {
  --primary: #2d68f8;
  --secondary: #445275;
  --tertiary: #6677a2;
  --stroke: #dfe4ea;
  --dark-1: #111928;
  --dark-2: #1f2a37;
  --dark-3: #374151;
  --dark-4: #4b5563;
  --dark-5: #6b7280;
  --dark-6: #9ca3af;
  --dark-7: #d1d5db;
  --dark-8: #e5e7eb;
  --white: #ffffff;
  --gray-1: #f9fafb;
  --gray-2: #f3f4f6;
  --gray-3: #e5e7eb;
  --gray-4: #dee2e6;
  --gray-5: #ced4da;
  --gray-6: #ced4da;
  --gray-7: #f1f4ff;
  --blue-dark: #1c3fb7;
  --blue-light: #5475e5;
  --blue-light-2: #8099ec;
  --blue-light-3: #adbcf2;
  --blue-light-4: #c3cef6;
  --blue-light-5: #e1e8ff;
  --blue-light-6: #eff2ff;
  --blue-light-7: #f4f6ff;
  --blue-light-8: #c8d8ff;
  --purple: #8646f4;
  --purple-dark: #6d28d9;
  --purple-dark-2: #5b21b6;
  --purple-light: #a78bfa;
  --purple-light-2: #c4b5fd;
  --purple-light-3: #ddd6fe;
  --purple-light-4: #ede9fe;
  --purple-light-5: #f5f3ff;
  --green: #22ad5c;
  --green-dark: #1a8245;
  --green-light: #2cd673;
  --green-light-2: #57de8f;
  --green-light-3: #82e6ac;
  --green-light-4: #acefc8;
  --green-light-5: #c2f3d6;
  --green-light-6: #daf8e6;
  --orange: #f27430;
  --orange-dark: #e1580e;
  --orange-light: #f59460;
  --orange-light-2: #f8b490;
  --orange-light-3: #fbd5c0;
  --orange-light-4: #fde5d8;
  --orange-light-5: #fff0e9;
  --red: #f23030;
  --red-dark: #e10e0e;
  --red-light: #f56060;
  --red-light-2: #f89090;
  --red-light-3: #fbc0c0;
  --red-light-4: #fdd8d8;
  --red-light-5: #feebeb;
  --red-light-6: #fef3f3;
  --yellow: #e1ff01;
  --duration: 300ms;
  --duration-md: 500ms;
  --duration-xl: 1000ms;
  --duration-2xl: 1500ms;
  --duration-3xl: 2000ms;
  --header-height: 72px;
}
