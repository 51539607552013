.product-vacancies {
  grid-template-columns: 1fr minmax(0px, 120px) repeat(2, min(50%, 600px)) minmax(
      0px,
      120px
    ) 1fr;
  overflow: hidden;
}
.product-image {
  max-width: var(--max-w-product-head-image);
}
.product-button-list {
  flex-direction: column;
  gap: 8px;
}
.product-button-list a {
  width: fit-content;
}
.product-button {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 12px 8px 8px;
  gap: 8px;
  border-radius: 8px;
  cursor: default;
  transition: all var(--duration);
  color: var(--tertiary);
  border: none;
  outline: none;
  background-color: transparent;
}
.product-button:hover {
  background-color: var(--white);
}
@media (min-width: 769px) {
  .product-button:focus {
    color: var(--primary);
  }
}
.product-button.active {
  color: var(--primary);
  background-color: var(--blue-light-5);
}
.product-button:not(.active):hover {
  cursor: pointer;
  color: var(--primary);
}
.product-button-icon {
  display: flex;
  padding: 4px;
  border-radius: 4px;
  transition: all var(--duration);
}
.product-button-icon.active {
  background-color: var(--white);
}

.product-images-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.marquee-box {
  position: relative;
  overflow: hidden;
}
.marquee-box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  right: 0;
  top: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(239, 242, 255, 0) 45%,
    rgba(239, 242, 255, 0.7) 70.15%,
    var(--blue-light-7) 91.71%
  );
  z-index: 2;
}
.marquee-box::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 100%;
  right: 0;
  top: 0;
  background: linear-gradient(
    90deg,
    rgba(239, 242, 255, 0) 45%,
    rgba(239, 242, 255, 0.7) 70.15%,
    var(--blue-light-7) 91.71%
  );
  z-index: 2;
}
.moving-image {
  position: absolute;
  background-size: contain;
  background-color: white;
  transition: all 1s linear;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 5px 20px 0px rgba(45, 104, 248, 0.1);
}
.moving-image > .description {
  margin-top: 12px;
  margin-right: -12px;
  padding: 0 16px 16px;
}
.moving-image.current {
  z-index: 1;
}
.moving-image.current,
.moving-image.right {
  opacity: 1;
}
.moving-image.top,
.moving-image.default {
  opacity: 0;
}
.product-section {
  display: flex;
}
.product-section-description {
  display: flex;
  flex-direction: column;
}
.product-chip {
  width: fit-content;
  padding: 4px 12px;
  border-radius: 16px;
  background: var(--white);
  color: var(--tertiary);
  box-shadow: 0px 5px 20px 0px rgba(45, 104, 248, 0.1);
}
.product-section-description-item {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  transition: all var(--duration);
}
.product-section-image {
  width: 100%;
  position: sticky;
  top: 25%;
  height: auto;
  transition: all var(--duration);
  grid-row: 1;
  grid-column: 1;
  display: grid;
}
.product-section-image:not(.active) {
  opacity: 0;
}
.product-section-image.active {
  opacity: 1;
}
.product-images {
  min-height: 100vh;
}
.product-image-card {
  position: absolute;
  z-index: 2;
  grid-column: 2 / 3;
  transform: rotate(11deg);
  transition: all var(--duration);
  border-radius: 10px;
}
.side-right:hover > .relative > .product-image-card {
  transform: rotate(0);
  z-index: 1;
}

.contact-form-image {
  max-width: 280px;
}
.contact-form-image.de {
  max-width: 340px;
}

.contact-form-card {
  background-color: var(--white);
  border-radius: 16px;
}
.contact-form-about {
  height: fit-content;

  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
.contact-f-image {
  margin-top: auto;
}
