.about-icon-container {
  background-color: var(--white);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  width: fit-content;
  position: absolute;
  z-index: 1;
  animation: orbit 10s linear infinite;
}
.about-icon-container > svg {
  width: 20px;
  height: 20px;
  color: var(--blue-light-3);
}

@keyframes orbit {
  0% {
    transform: translate(20px, 0) rotate(var(--rotate));
  }
  25% {
    transform: translate(0, 20px) rotate(var(--rotate));
  }
  50% {
    transform: translate(-20px, 0) rotate(var(--rotate));
  }
  75% {
    transform: translate(0, -20px) rotate(var(--rotate));
  }
  100% {
    transform: translate(20px, 0) rotate(var(--rotate));
  }
}

.about-icon-1 {
  animation-duration: 20s;
}
.about-icon-2 {
  animation-duration: 30s;
}
.about-icon-3 {
  animation-duration: 40s;
}
.about-icon-4 {
  animation-duration: 25s;
}
.about-icon-5 {
  animation-duration: 35s;
}
.about-icon-6 {
  animation-duration: 45s;
}
