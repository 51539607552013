.text-secondary {
  color: var(--secondary);
}
.text-tertiary {
  color: var(--tertiary);
}
.text-primary {
  color: var(--primary);
}
.text-dark-3 {
  color: var(--dark-3);
}
.text-dark-5 {
  color: var(--dark-5);
}
.text-red {
  color: var(--red);
}
.text-blue-light-4 {
  color: var(--blue-light-4);
}
.text-green {
  color: var(--green-light);
}
.stroke-yellow {
  stroke: var(--yellow);
}
.stroke-white {
  stroke: var(--white);
}
.fill-social {
  --fill-yellow: var(--yellow);
}
.fill-yellow {
  fill: var(--fill-yellow);
}
svg * {
  transition: all var(--duration);
}
.text-hero {
  font-size: 34px;
  line-height: 42px;
  color: var(--secondary);
}
h1 {
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
}
h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}
h2 > * {
  font-weight: 400;
  line-height: 36px;
  vertical-align: top;
}
h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}
h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.text-md {
  font-size: 20px;
  line-height: 28px;
}
a {
  text-decoration: none;
  color: currentColor;
}
section {
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}
section.proximity {
  scroll-snap-type: y proximity;
}
section h2 {
  position: sticky;
  top: 0;
  padding: 10px;
  text-align: center;
  z-index: 1;
}
section ul {
  list-style-type: none;
}
section li {
  height: 80vh;
  display: flex;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  scroll-snap-align: start;
}

.body-large {
  font-size: 18px;
  line-height: 26px;
}
.body-medium {
  font-size: 16px;
  line-height: 24px;
}
.body-regular {
  font-size: 16px;
  line-height: 22px;
}
.body-small {
  font-size: 14px;
  line-height: 22px;
}
.body-extra-small {
  font-size: 12px;
  line-height: 20px;
}
.body-extra-2-small {
  font-size: 10px;
  line-height: 20px;
}

.weight-300 {
  font-weight: 300;
}
.weight-400 {
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
.weight-600 {
  font-weight: 600;
}
.weight-700 {
  font-weight: 700;
}
.letter-spacing-1 {
  letter-spacing: 1px;
}
.text-uppercase {
  text-transform: uppercase;
}

.fit {
  width: fit-content;
}
.block {
  display: block;
}

.ol-terms {
  max-width: 700px;
}
ol.ol-terms > li {
  margin-left: 24px;
}
.ul-terms-disc {
  list-style-type: disc;
}
.ul-terms-spacing {
  margin-left: 24px;
}
.ul-terms-spacing-50 {
  margin-left: 50px;
}
.ul-terms-spacing-70 {
  margin-left: 70px;
}
.ul-terms-spacing-50 > li::marker,
.ul-terms-spacing-70 > li::marker,
.ul-terms-spacing > li::marker {
  font-weight: 400;
}
ol.ol-terms > li > .body-small {
  margin-left: -24px;
}
ol > li::marker {
  font-weight: 600;
}
.ol-terms > li::marker {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}

.overflow-hidden {
  overflow: hidden;
}

.shadow-1 {
  box-shadow: 0px 1px 3px 0px rgba(166, 175, 195, 0.4);
}
.shadow-2 {
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
}
.shadow-3 {
  box-shadow: 0px 4px 12px 0px rgba(13, 10, 44, 0.06);
}
.shadow-4 {
  box-shadow: 0px 10px 15px 0px rgba(5, 13, 29, 0.18);
}
.shadow-5 {
  box-shadow: 0px 34px 26px 0px rgba(13, 10, 44, 0.05),
    0px 12px 34px 0px rgba(13, 10, 44, 0.08);
}
.shadow-6 {
  box-shadow: 0px 20px 20px 0px rgba(5, 13, 29, 0.2);
}
.shadow-7 {
  box-shadow: 0px 5px 20px 0px rgba(45, 104, 248, 0.1);
}
.shadow-8 {
  box-shadow: 0px 10px 30px 0px rgba(45, 104, 248, 0.2);
}

.button {
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  transition: all var(--duration);
}
.contained-button-with-icon {
  white-space: nowrap;
  color: var(--white);
  border: none;
  background-color: var(--primary);
  padding: 8px 16px;

  display: flex;
  align-items: center;
  gap: 8px;

  transition: all var(--duration);
}
.contained-button-with-icon:hover {
  background-color: var(--blue-dark);
}
.outlined-button-with-icon {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 16px;
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
  transition: all var(--duration);
}
.outlined-button-icon {
  width: 24px;
  height: 24px;
  background-color: var(--blue-light-5);
  border-radius: 50%;
  padding: 4px 4px 4px 5px;
  transition: all var(--duration);
  display: flex;
}
.prices-card:nth-of-type(even) > .outlined-button-with-icon {
  border-color: var(--white);
}
.prices-card:nth-of-type(even) > .outlined-button-with-icon:hover {
  border-color: var(--yellow);
  background-color: var(--yellow);
}
.prices-card > ul {
  margin-top: 20px;
}
.contained-button-icon {
  padding-left: 1px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--yellow);

  display: flex;
  align-items: center;
  justify-content: center;
}
.contained-button-icon,
.outlined-button-icon {
  margin: -1px;
  transition: all var(--duration);
}
.stroke {
  stroke: var(--primary);
  transition: all var(--duration);
}
.contained-button-with-icon:hover > .contained-button-icon > svg > .stroke {
  stroke: var(--blue-dark);
}
.stroke-outlined {
  stroke: var(--primary);
  transition: all var(--duration);
}
.outlined-button-with-icon:hover {
  background-color: var(--white);
}
.outlined-button-with-icon:hover > .outlined-button-icon {
  background-color: var(--primary);
}
.outlined-button-with-icon:hover
  > .outlined-button-icon
  > svg
  > .stroke-outlined {
  stroke: var(--yellow);
}

.contained-primary {
  color: var(--white);
  border: 1px solid var(--primary);
  background-color: var(--primary);
}
.contained-primary:hover {
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
}
.contained-primary:disabled {
  color: var(--dark-6);
  background-color: var(--gray-3);
  pointer-events: none;
  border-color: var(--gray-3);
}

.outlined-primary {
  color: var(--primary);
  border: 1px solid var(--primary);
  background-color: var(--white);
}
.outlined-primary:hover {
  background-color: var(--blue-light-5);
}
.outlined-tertiary {
  color: var(--primary);
  border: 1px dashed var(--primary);
  background-color: var(--white);
}
.outlined-tertiary:hover {
  background-color: var(--blue-light-5);
}
.outlined-primary:disabled,
.outlined-tertiary:disabled {
  color: var(--dark-6);
  border-color: var(--gray-6);
  background-color: var(--gray-3);
  pointer-events: none;
}

.button-text {
  color: var(--secondary);
  border: none;
  background: transparent;
  transition: all var(--duration);
}
.button-text:hover.body-medium {
  text-decoration: underline;
}
.button-navigation {
  margin: 0 12px;
  position: relative;
  height: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--secondary);
  transition: all var(--duration);
}
.button-text:not(.l):hover,
.button-text:not(.l):hover > svg {
  color: var(--primary);
  cursor: pointer;
  border: none;
  background: transparent;
  transition: all var(--duration);
}
.button-navigation:hover {
  color: var(--primary);
  cursor: pointer;
  border: none;
  background: transparent;
  transition: all var(--duration);
}
.button-navigation.active {
  color: var(--primary);
  transition: all var(--duration);
}
.button-navigation::after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  background-color: var(--primary);
  bottom: 0;
  transition: all var(--duration);
  width: 0%;
}
.button-navigation.active::after,
.button-navigation.active:hover::after,
.button-navigation:hover::after {
  transition: all var(--duration);
  width: 100%;
}
.button-navigation.expanded > .dropdown-icon {
  transform: rotate(-180deg);
  transition: all var(--duration);
}
.icon-button {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--blue-light-4);
  display: flex;

  cursor: pointer;
}
.products-expanded-list {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  max-width: 790px;
  margin: 0 auto;
  background-color: var(--white);
  border-radius: 12px;
  padding: 32px;
  pointer-events: none;
  opacity: 0;
  transition: all var(--duration);

  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(400px, 1fr);
  gap: 8px;
}
.products-expanded-list.expanded {
  opacity: 1;
  pointer-events: all;
}
.products-expanded-list-description-container {
  background-color: var(--blue-light-6);
  border-radius: 12px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  grid-column: 2 / 3;
  grid-row: 1 / 7;
}
.products-expanded-list-description-container.default-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  z-index: 1;
  transition: all var(--duration);
}
.products-expanded-list-description-container.default-list > svg {
  width: 45px;
  height: 45px;
  color: var(--blue-light-4);
}
.products-expanded-list-description-container svg {
  stroke-width: 0.75px;
}
.products-expanded-list-description {
  padding: 36px;
  list-style: none;
  z-index: 2;
  position: relative;
  opacity: 0;
  transition: all var(--duration);
  height: 100%;
}
.products-expanded-list-description.default > .list {
  opacity: 1;
}
.products-expanded-list-description > li:not(:first-of-type) {
  margin-top: 8px;
}
.products-expanded-list-description > li {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  line-height: 22px;
  color: var(--secondary);
  z-index: 1;

  transition: all var(--duration);
}
.products-expanded-list-description.visible {
  opacity: 1;
  z-index: 2;
}
.products-expanded-list-description > svg {
  position: absolute;
  right: 24px;
  bottom: 24px;
  opacity: 0;
  transition: all var(--duration);
  color: var(--blue-light-4);
  width: 45px;
  height: 45px;
}
.products-expanded-list-description.visible > svg {
  opacity: 1;
}
.products-expanded-list-description > ul > li:not(:first-of-type) {
  margin-top: 8px;
}
.products-expanded-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  font-size: 14px;
  line-height: 22px;
  color: var(--secondary);
  transition: all var(--duration);
}
.products-expanded-button:hover {
  color: var(--primary);
  cursor: pointer;
}
.products-expanded-button-icon {
  display: flex;
  border-radius: 4px;
  padding: 4px;
  transition: all var(--duration);
}
.products-expanded-button-icon > svg {
  width: 20px;
  height: 20px;
}
.products-expanded-button:hover > .products-expanded-button-icon {
  background-color: var(--blue-light-6);
}
.products-expanded-extra {
  color: var(--white);
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: var(--red-light-2);
}
.f.expanded {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(161, 172, 191, 0.5);
  z-index: 1001;
}

.terms-divider {
  width: 100%;
  height: 1px;
  background-color: var(--blue-light-5);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1232px;
  margin: 0 auto;
  height: var(--header-height);
  position: sticky;
  top: 0;
  margin-top: 16px;
  padding: 0 16px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(25px);
  z-index: 9999;
  transition: all var(--duration);
}
.header.expanded {
  background-color: var(--white);
}
.footer {
  position: relative;
  border-top: 1px solid var(--blue-light-4);
  z-index: 1000;
  max-width: 1200px;
  margin: 30px auto 0;
  background-color: var(--blue-light-7);
}
.footer-gradient {
  right: 28px;
  z-index: -1;
  position: absolute;
  bottom: 0px;
}
.footer-logo {
  min-width: 114px;
  margin-bottom: 12px;
}
.footer-description {
  max-width: 380px;
}
.footer-socials {
  margin-top: 42px;
  display: flex;
  gap: 16px;
}
.footer-social {
  display: flex;
  gap: 16px;
  transition: all var(--duration);
}
.footer-social:hover {
  transform: scale(1.1);
}
.footer-terms {
  display: flex;
  align-items: center;
  gap: 24px;
}
.a {
  width: fit-content;
  text-decoration: underline;
  transition: all var(--duration);
  text-decoration-color: transparent;
  text-underline-offset: 4px;
  -moz-text-decoration-color: transparent;
  -webkit-text-decoration-color: transparent;
}
.a:hover {
  text-decoration-color: currentColor;
  -moz-text-decoration-color: currentColor;
  -webkit-text-decoration-color: currentColor;
}
.section {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.section.section-cookies {
  padding: 16px;
  display: flex;
  justify-content: center;
}
.section.not-found {
  padding: 0 16px 16px;
  display: flex;
  justify-content: center;
}
.section.section-cookies svg {
  width: 100%;
  height: auto;
  max-width: 436px;
  color: var(--tertiary);
}
.section.not-found svg {
  width: 100%;
  max-width: 658px;
  height: auto;
}
.scroll-to-section {
  display: flex;
}
.section-terms {
  margin: 100px auto 120px;
}
.section-platform {
  margin-top: 28px;
}
.section-question {
  padding: 40px 16px 0;
  max-width: 620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.section-innovate-head {
  max-width: 630px;
}
.section-innovate-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 36px;
}
.innovate-list-card {
  padding: 32px;
  border-radius: 12px;
  background-color: var(--white);
  max-width: calc(50% - 12px);
}
.innovate-list-card img {
  width: 100%;
}
.section-innovate-content {
  display: flex;
  gap: 24px;
  margin: 16px auto 0;
  width: 100%;
  justify-content: space-between;
}
.section-innovate-footer {
  padding: 60px 0 120px;
  margin: 0 auto;
  width: fit-content;
}
.section-bother {
  padding-top: 100px;
  margin: 100px auto 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section-bother-pulse {
  margin-top: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-bother-pulse.rvws {
  min-height: 80vh;
  margin-top: 170px;
}
.section-bother-pulse.abt {
  margin-top: 40px;
}
.section-tasks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  padding: 100px 0 60px;
}
.section-tasks .rounded-item {
  padding: 0;
  align-items: start;
  background-color: transparent;
}
.section-opportunities,
.section-prices {
  margin: 0 auto;
  padding: 100px 0 60px;
}
.section-prices-page {
  margin: 0 auto;
  padding: 60px 0;
}
.section-prices-page-content {
  padding: 80px 0 120px;
}
.section-prices-page-gradient {
  position: absolute;
  left: -19%;
  top: -220px;
  z-index: -1;
  max-height: 380px;
}
.prices-table {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.prices-table-row {
  display: grid;
  grid-template-columns: 3fr repeat(3, minmax(207px, 1fr));
  gap: 8px;
  border-radius: 8px;
  transition: all var(--duration-md);
}
.prices-table-row.active {
  background-color: var(--blue-light-5);
}
.prices-table-row:not(.head):hover {
  background-color: var(--blue-light-5);
  cursor: pointer;
}
.prices-table-row.head {
  padding: 4px 0;
  position: sticky;
  top: var(--header-height);
  background-color: var(--blue-light-7);
  z-index: 1;
  align-items: center;
  border-radius: 0;
}
.prices-table-element {
  border-radius: 8px;
  text-align: center;
  padding: 8px;
  color: var(--secondary);
  background-color: var(--blue-light-5);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  width: 100%;
}
.prices-table-element:nth-of-type(odd) {
  color: var(--white);
  background-color: var(--primary);
}
.prices-table-cell {
  display: flex;
  padding: 10px;
  font-size: 16px;
  line-height: 22px;
  color: var(--secondary);
  transition: all var(--duration);
}
.prices-table-cell:not(.accordion) {
  text-align: center;
  align-items: center;
}
.prices-table-cell-accordion-item {
  --collapsed-content-mt: 12px;
  display: flex;
  gap: 8px;
}
.prices-table-cell-accordion-item > svg {
  margin: 1px;
  transition: all var(--duration);
}
.prices-table-cell-accordion-item.active > svg {
  transform: rotate(-180deg);
  transition: all var(--duration);
}
.prices-table-cell.accordion {
  display: flex;
  flex-direction: column;
}
.table-mt.active {
  --collapsed-content-mt: 12px;
}
.collapsed-content {
  max-height: 0;
  overflow: hidden;
  color: var(--tertiary);
  margin-top: var(--collapsed-content-mt, 0px);
  transition: all var(--duration);
  font-size: 14px;
  line-height: 20px;
}
.head {
  margin-top: 40px;
}
.prices-options-text {
  color: var(--secondary);
}
.table-divider {
  margin: 12px 0;
  border-bottom: 1px solid var(--tertiary);
}
.section-integrations {
  display: flex;
  justify-content: space-between;
}
.section-integrations-text {
  max-width: 428px;
}
.section-integrations-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
}
.section-integration-slack {
  margin-top: 40px;
  display: flex;
  gap: 40px;
}
.integration-side-left {
  flex-basis: 60%;
}
.integration-side-right {
  flex-basis: 40%;
}
.integration-navigation {
  position: sticky;
  top: calc(var(--header-height) + 40px);
  background-color: var(--white);
  padding: 28px;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  color: var(--secondary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.integration-section {
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.integration-section > * {
  scroll-margin-top: calc(var(--header-height) + 16px);
}
.integration-note {
  padding: 16px;
  border-radius: 12px;
  background-color: var(--green-light-5);
}
.a-integration {
  transition: all var(--duration);
}
.a-integration:hover {
  color: var(--blue-dark);
}
.integration-slack-list {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.integration-slack-list .integrations-card:nth-of-type(1) {
  align-self: center;
  transform: rotate(-3deg);
}
.integration-slack-list .integrations-card:nth-of-type(2) {
  transform: rotate(7deg);
}
.integration-slack-list .integrations-card:nth-of-type(3) {
  align-self: flex-end;
  transform: rotate(-10deg) translateY(-50%);
}
.integration-slack-head {
  display: flex;
  justify-content: space-between;
}
.section-target {
  padding: 100px 0 60px;
}
.section-target-list {
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.section-target-card {
  padding: 24px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  gap: 24px;
}
.section-target-image {
  padding: 4px;
  height: 84px;
  width: 84px;
  background-color: var(--white);
  border-radius: 8px;
}
.section-reviews {
  min-height: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-faq {
  padding: 80px 0 120px;
  background-color: var(--blue-light-5);
}
.faq-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 680px;
  margin: 60px auto 0;
}
.faq-card {
  background-color: var(--blue-light-7);
  border-radius: 12px;
  padding: 28px;
}
.faq-card:hover {
  cursor: pointer;
}
.faq-answer {
  max-height: 0px;
}
.faq-answer.active {
  --collapsed-content-mt: 24px;
}
.faq-question {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  color: var(--secondary);
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;

  cursor: pointer;
}
.icon-24.absolute {
  transform: rotate(90deg);
  transition: all var(--duration);
}
.icon-24.absolute.active {
  transform: rotate(0deg);
}
.question-dots {
  transform: translateX(-50%);
}
.question-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-top: 24px;
  transition: all var(--duration);
  animation: colorCycle 1.5s infinite;
}
.question-dot:nth-of-type(1) {
  animation-delay: 0s;
}
.question-dot:nth-of-type(2) {
  animation-delay: 0.5s;
}
.question-dot:nth-of-type(3) {
  animation-delay: 1s;
}
@keyframes colorCycle {
  0%,
  100% {
    background-color: var(--blue-light);
  }
  33.33% {
    background-color: var(--blue-light-3);
  }
  66.66% {
    background-color: var(--blue-light-5);
  }
}
.opportunities-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 22px;

  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.opportunities-buttons::-webkit-scrollbar {
  display: none;
}
.opportunities-button-container {
  padding: 0 30px;
  position: relative;
}
.opportunities-button-container .after-gradient-right-to-left::after,
.opportunities-button-container .before-gradient-left-to-right::before {
  width: 200px;
}
.opportunities-button {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: var(--secondary);
  padding: 12px 16px;
  transition: all var(--duration);
  cursor: default;
}
.opportunities-button span {
  text-wrap: nowrap;
}
.opportunities-button:not(.active):hover {
  cursor: pointer;
}
.opportunities-button:hover {
  color: var(--primary);
  background-color: var(--white);
}
.opportunities-button.active {
  color: var(--primary);
  background-color: var(--blue-light-5);
}
.opportunities-button:not(.active):hover > .icon-container {
  background-color: var(--white);
}
.opportunities-button:not(.active) > .icon-container {
  background-color: transparent;
}
.opportunities-content {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.opportunities-description {
  margin: 80px 0;
  display: grid;
}
.opp-text {
  grid-area: 1 / 1;
  transition: all var(--duration-xl);
}
.opportunities-image {
  position: absolute;
  aspect-ratio: 700 / 450;
  inset: 0px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: all var(--duration-xl);
  transform-origin: 0% 0%;
}
.opportunities-image-container {
  border-radius: 12px;
  overflow: hidden;
  max-width: 700px;
  width: 100%;
  height: 100%;
  position: relative;
  aspect-ratio: auto;
  aspect-ratio: 700 / 450;
}
.opportunities-text {
  margin-top: 12px;
  max-width: 470px;
  color: var(--tertiary);
}
.prices-save {
  position: absolute;
  left: 100%;
  text-wrap: nowrap;
  margin-left: 8px;
  background-color: var(--blue-light-5);
  padding: 2px 8px;
  border-radius: 12px;
  color: var(--blue-light-2);
  transition: all var(--duration);
}
.prices-save.active {
  color: var(--primary);
  background-color: var(--yellow);
}
.prices-switch {
  margin: 32px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: fit-content;
  position: relative;
}
.switch {
  padding: 2px;
  min-width: 48px;
  border-radius: 30px;
  background-color: var(--primary);
  cursor: pointer;
  transition: all var(--duration);
}
.switch.disabled {
  background-color: var(--dark-8);
  cursor: default;
}
.switch.unchecked {
  background-color: var(--dark-6);
}
.switch-text {
  position: absolute;
  color: var(--tertiary);
  transition: all var(--duration);
}
.switch-text.left {
  right: calc(100% + 16px);
}
.switch-text.right {
  left: calc(100% + 16px);
}
.switch-text.active {
  color: var(--primary);
}
.thumb {
  border-radius: 50%;
  background-color: var(--white);
  transition: all var(--duration);

  display: flex;
  align-items: center;
  justify-content: center;
}
.thumb.disabled {
  background-color: var(--gray-1);
}
.thumb:not(.active) {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
}
.thumb:not(.disabled) > svg {
  color: var(--primary);
}
.thumb.disabled > svg {
  color: var(--dark-8);
}
.thumb.active {
  transform: translateX(24px);
}
.thumb.active.prcs {
  background-color: var(--yellow);
}
.thumb::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  border: 6px solid transparent;
  transition: all var(--duration);
  height: 20px;
  width: 20px;
  opacity: 0.3;
}
.thumb.disabled::before {
  display: none;
}
.switch:hover .thumb.unchecked::before {
  border-color: var(--dark-6);
}
.switch:hover .thumb.active::before {
  border-color: var(--primary);
}
.switch:hover .thumb.prcs:not(.active)::before {
  border-color: var(--primary);
}
.switch:hover .thumb.prcs.active::before {
  border-color: var(--blue-dark);
}
.prices-card-list-1,
.prices-card-list-2 {
  margin-top: 42px;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.prices-card {
  border-radius: 12px;
  color: var(--secondary);
  background-color: var(--blue-light-5);

  display: flex;
  flex-direction: column;
  flex-basis: 33.3%;

  font-size: 14px;
  line-height: 22px;
  position: relative;
}
.price-card-icon {
  display: flex;
  padding: 8px;
  border-radius: 50%;
  background-color: var(--white);
}
.price-card-icon-container {
  position: absolute;
  top: 24px;
  right: 24px;
}
.price-card-icon-container > svg {
  width: 20px;
  height: 20px;
}
.price {
  margin-top: 12px;
}
.prices-divider {
  height: 1px;
  width: 100%;
  margin: 20px 0;
  background-color: var(--blue-light-3);
}
.prices-description {
  margin-top: 20px;
  color: var(--tertiary);
  min-height: 2.75rem;
}
.prices-card:nth-of-type(even) > .prices-description {
  color: var(--white);
}
.prices-card:nth-of-type(odd) > .prices-title {
  color: var(--primary);
}
.prices-card:nth-of-type(even) > .prices-divider {
  background-color: var(--white);
}
.prices-card:nth-of-type(even) {
  color: var(--white);
  background-color: var(--primary);
}
.prices-card:nth-of-type(even) > .prices-button {
  color: var(--white);
}
.prices-button {
  margin-top: auto;
  padding-top: 20px;
  width: fit-content;
  color: var(--primary);
}
.prices-button:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}
.prices-button,
.prices-extra {
  font-size: 16px;
  line-height: 22px;
}
.prices-extra {
  color: var(--tertiary);
}
.prices-join-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background-color: var(--white);
  padding: 24px;
  border-radius: 12px;
  margin: 16px auto 0px;
}
.prices-sparcles {
  margin-bottom: auto;
  height: 22px;
  display: flex;
  align-items: center;
}
.tasks-head > img {
  min-width: 184px;
}
.tasks-head > p {
  color: var(--secondary);
  max-width: 600px;
  text-align: center;
}
.tasks-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.tasks-images {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  position: relative;
  color: var(--tertiary);
  transition: all var(--duration-md);
}
.tasks-images svg {
  display: flex;
  width: 100%;
  height: 100%;
}
.tasks-images .i {
  max-width: 150px;
  border-radius: 12px;
  overflow: hidden;
  transition: all var(--duration-md);
  position: relative;
}
.tasks-images .i:nth-of-type(6)::after {
  content: "";
  background: url("assets/images/en/task-5-avatar.jpg") center/42px no-repeat;
  position: absolute;
  left: 15px;
  top: 28px;
  width: 42px;
  height: 42px;
  border-radius: 8px;
  transition: all var(--duration-xl);
}
.tasks-images .uk.i:nth-of-type(6)::after {
  top: 30px;
}
.tasks-images .de.i:nth-of-type(8)::after {
  right: 14%;
  top: 45.5%;
}
.tasks-images .active.i:nth-of-type(6)::after {
  opacity: 0;
  transition: all var(--duration-xl);
}
.tasks-images :not(.active).i:nth-of-type(6)::after {
  opacity: 1;
  transition: all var(--duration-xl);
}
.tasks-images .fill-secondary,
.tasks-images .fill-tertiary {
  fill: var(--blue-light-2);
}
.tasks-images .active .fill-tertiary {
  fill: var(--tertiary);
}
.tasks-images .active .fill-secondary {
  fill: var(--secondary);
}
.tasks-images .fill-green {
  fill: var(--blue-light-4);
}
.tasks-images .active .fill-green {
  fill: var(--green-light);
}
.tasks-images .fill-blue {
  fill: var(--blue-light-2);
}
.tasks-images .active .fill-blue {
  fill: var(--primary);
}
.tasks-images .stroke-green {
  stroke: var(--blue-light-4);
}
.tasks-images .active .stroke-green {
  stroke: var(--green-light);
}
.tasks-images .stroke-primary {
  stroke: var(--blue-light-2);
}
.tasks-images .active .stroke-primary {
  stroke: var(--primary);
}
.slideshow-container {
  width: 100%;
  position: relative;
  margin: auto;
  height: 22px;
  overflow: hidden;
}
.slides {
  margin-left: -16px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  text-align: center;
}
.slide-in {
  opacity: 1;
  transform: translateY(0%);
}
.slide-out-up {
  opacity: 0;
  transform: translateY(100%);
}
.slide-out-down {
  opacity: 0;
  transform: translateY(-100%);
}
#page-content-home .text-hero {
  max-width: 708px;
  text-align: center;
  margin: 0 auto;
}
.home-ticker-container {
  max-width: 1440px;
  margin: 86px auto 0;
  overflow: hidden;
  position: relative;
}
.ticker-box {
  white-space: nowrap;
  display: flex;
}
.ticker-item {
  position: relative;
  display: inline-block;
  margin-right: 40px;
  min-height: 100%;
}
@media (max-width: 768px) {
  .ticker-item {
    margin-right: 20px;
  }
}
.card-innovate {
  border-radius: 12px;
  background-color: var(--white);
  padding: 24px;
}
.card-bother-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 150px));
  gap: 20px;
  max-width: 490px;
  z-index: 1;
  height: 100%;
  grid-template-rows: repeat(2, 1fr);
}
.card-bother {
  border-radius: 12px;
  padding: 16px 12px 12px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 5px 20px 0px rgba(45, 104, 248, 0.1);
  backdrop-filter: blur(8px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  gap: 12px;
  transition: all var(--duration-2xl);
  word-break: break-word;
}
.card-bother.active {
  background-color: var(--white);
}
.card-bother > p {
  color: var(--tertiary);
  transition: all var(--duration-2xl);
}
.card-bother.active > p {
  color: var(--secondary);
  transition: all var(--duration-2xl);
}
.card-bother.active > .icon-container {
  background-color: var(--blue-light-6);
  transition: all var(--duration-md);
}
.card-bother-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  z-index: 1;
  height: 100%;
}
.card-bother-textscroll {
  position: relative;
}
.navigation {
  display: flex;
  gap: 8px;
  height: 100%;
  margin: 0 auto 0 32px;
}
.icon {
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}
.tab {
  color: var(--tertiary);
  border-bottom: 1px solid;
  border-color: transparent;
  padding: 4px 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: -1px;
  transition: all var(--duration);
}
.tab:hover,
.tab.active {
  color: var(--secondary);
  border-color: var(--blue-light-3);
  background-color: var(--white);
}
.tab:not(.active) {
  cursor: pointer;
}
.tabs {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid var(--blue-light-5);
  width: fit-content;
  margin: 50px auto;
}
.hero-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 24px auto 0;
}
.header-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}
.hero-image {
  width: 100%;
  height: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.before-gradient {
  position: relative;
}
.before-gradient::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 468px;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(244, 246, 255, 0) 6.94%,
    var(--blue-light-7) 92.09%
  );
}
.after-gradient-right-to-left::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(
    90deg,
    rgba(225, 232, 255, 0) 5.5%,
    var(--blue-light-7) 100%
  );
}
.before-gradient-left-to-right::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(
    270deg,
    rgba(225, 232, 255, 0) 5.5%,
    var(--blue-light-7) 100%
  );
}
.home-ticker-container.before-gradient-left-to-right::before,
.home-ticker-container.after-gradient-right-to-left::after {
  display: none;
}
.blur-bg-1 {
  --blur-bg: rgba(45, 104, 248, 0.18);
}
.blur-bg-2 {
  --blur-bg: rgba(67, 1, 255, 0.12);
}
.blur-bg-3 {
  --blur-bg: rgba(67, 1, 255, 0.1);
}
.blur-bg-4 {
  --blur-bg: rgba(45, 104, 248, 0.15);
}
.blur-bg-5 {
  --blur-bg: var(--blue-light-8);
}
.blur-bg-6 {
  --blur-bg: rgba(45, 104, 248, 0.1);
}
.blur-bg-7 {
  --blur-bg: rgba(44, 104, 248, 0.8);
}
.blur-filter-50 {
  --filter-blur: 50px;
}
.blur-filter-70 {
  --filter-blur: 70px;
}
.blur-filter-90 {
  --filter-blur: 90px;
}
.blur-filter-112-5 {
  --filter-blur: 112.5px;
}
.blur-size-293 {
  --size: 293px;
}
.blur-size-312 {
  --size: 312px;
}
.blur-size-326 {
  --size: 326px;
}
.blur-size-400 {
  --size: 400px;
}
.blur-size-460 {
  --size: 460px;
}
.blur-size-500 {
  --size: 500px;
}
.blur-size-600 {
  --size: 600px;
}
.blur-ball {
  position: absolute;
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  filter: blur(var(--filter-blur));
  background-color: var(--blur-bg);
  z-index: -1;
}
.left-center {
  left: 30px;
  bottom: 30%;
}
.left-center-1 {
  left: -10%;
  bottom: -50px;
}
.center-center {
  left: 45%;
  top: 100px;
}
.left-bottom {
  left: -80px;
  bottom: 0px;
}
.left-bottom-2 {
  left: -160px;
  bottom: -120px;
}
.right-center {
  right: -10%;
  bottom: -50px;
}
.right-bottom {
  left: 50%;
  top: 30%;
}
.right-bottom-2 {
  right: 0;
  bottom: 50px;
}
.right-bottom-3 {
  left: 50%;
  top: calc(40px + 16px + var(--header-height));
}
.right-top {
  right: 300px;
  top: -100px;
}
.-z-1 {
  z-index: -1;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-1001 {
  z-index: 1001;
}
.top-left {
  top: 0;
  left: 0;
}
.top-n89-left {
  top: -89px;
  left: 0;
}
.top-right {
  top: 0;
  right: 0;
}
.top-right-2 {
  top: 200px;
  left: 52%;
}
.top-n53-right {
  top: -53px;
  right: 0;
}
.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.absolute-center-top-left {
  top: 25%;
  left: 25%;
  transform: translate(-50%, -50%);
}
.absolute-center-bottom-right {
  top: 75%;
  left: 75%;
  transform: translate(-50%, -50%);
}
.integrations-cards-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 630px;
  width: 100%;
}
.integrations-card {
  display: flex;
  gap: 8px;
  cursor: default;
  transition: all var(--duration-md);
}
.integrations-card-bg {
  display: flex;
  border-radius: 12px;
  background-color: var(--white);
}
.integrations-card-icon {
  padding: 8px;
}
.integrations-cards-blur-ball {
  left: 36%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all var(--duration-xl);
}
.integrations-card-text {
  max-width: 306px;
  color: var(--secondary);
  font-size: 14px;
  line-height: 22px;
  padding: 8px 12px;
}
.section-review-card,
.about-us-review-card {
  padding: 20px 24px;
  background-color: var(--white);
  border-radius: 12px;
  position: absolute;
  z-index: 2;

  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 260px;
  width: 100%;
}
.section-review-card:nth-child(1) {
  right: 100%;
  top: 50%;
  transform: translate(-24px, -90%);
}
.section-review-card:nth-child(2) {
  right: 50%;
  bottom: 90%;
  transform: translate(50%, 50%);
}
.section-review-card:nth-child(3) {
  left: 100%;
  top: 50%;
  transform: translate(24px, -90%);
}
.review-quote {
  position: absolute;
  background-color: var(--blue-light-5);
  border-radius: 12px;
  padding: 16px;
  max-width: 350px;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
  transition: all var(--duration-md);
}
.section-review-card:nth-child(1) > .relative > .review-quote {
  width: 340px;
  bottom: -60%;
  right: -8px;
  transform: rotate(10deg);
}
.section-review-card:nth-child(1):hover > .relative > .review-quote,
.section-review-card:nth-child(3):hover > .relative > .review-quote {
  bottom: -80%;
  transform: rotate(0deg) scale(1.1);
}
.section-review-card:nth-child(2) > .relative > .review-quote {
  width: 360px;
  top: -76%;
  right: -40%;
  transform: rotate(-5deg);
}
.section-review-card:nth-child(2):hover > .relative > .review-quote {
  top: -80%;
  transform: rotate(0deg) scale(1.1);
}
.section-review-card:nth-child(3) > .relative > .review-quote {
  width: 370px;
  bottom: -60%;
  left: -8px;
  transform: rotate(-10deg);
}
.section-review-card:nth-child(3):hover > .relative > .review-quote {
  bottom: -80%;
}
.about-us-review-card:nth-child(1) {
  right: 80%;
  bottom: 90%;
  transform: translate(50%, 50%);
}
.about-us-review-card:nth-child(2) {
  left: 30%;
  top: 120%;
  transform: translate(24px, -90%);
}
.about-us-review-card:nth-child(2):hover > .relative > .review-quote {
  bottom: -80%;
  transform: rotate(0deg) scale(1.1);
}
.about-us-review-card:nth-child(1) > .relative > .review-quote {
  width: 360px;
  top: -76%;
  right: -40%;
  transform: rotate(-5deg);
}
.about-us-review-card:nth-child(1):hover > .relative > .review-quote {
  top: -80%;
  transform: rotate(0deg) scale(1.1);
}
.about-us-review-card:nth-child(2) > .relative > .review-quote {
  width: 370px;
  bottom: -60%;
  left: -30%;
  transform: rotate(-10deg);
}
.about-us-review-card:nth-child(2):hover > .relative > .review-quote {
  bottom: -80%;
}
.review-card-head {
  display: flex;
  align-items: center;
  gap: 12px;
}
.review-card-head img {
  border-radius: 8px;
}
.review-card-location {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: var(--blue-light-7);
  width: fit-content;
  border-radius: 16px;
  padding: 4px 12px;
}
.modal-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(161, 172, 191, 0.5);
  pointer-events: none;
  opacity: 0;
  z-index: 10000;
  transition: all var(--duration);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-bg.expanded {
  pointer-events: all;
  opacity: 1;
}
.modal {
  max-height: 90vh;
  background-color: var(--white);
  border-radius: 16px;
  overflow: scroll;
  isolation: isolate;
}
.modal.modal-prices.prcs {
  max-width: 660px;
}
.modal.modal-prices {
  width: 100%;
  max-width: 500px;
}
.modal-form {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.modal-form > div {
  width: calc(50% - 6px);
}
.modal.modal-cookies {
  max-width: 600px;
}
.modal::-webkit-scrollbar {
  display: none;
}
.form-charity-radio {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondary);
}
.form-checkbox {
  margin: 4px 0 auto;
}
.checkbox {
  border: 1px solid var(--tertiary);
  border-radius: 4px;
  display: flex;
  height: fit-content;
  background-color: var(--white);
  position: relative;
}
.checkbox.err {
  border-color: var(--red);
}
.checkbox.checked {
  border-color: var(--secondary);
  background-color: var(--secondary);
}
.checkbox > img {
  opacity: 0;
}
.checkbox.checked > img {
  opacity: 1;
}
.checkbox::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 6px;
  margin: -6px;
  z-index: -1;
  transition: all var(--duration);
}
.checkbox:hover,
.checkbox:hover::before,
label:hover .checkbox,
label:hover .checkbox::before {
  background-color: var(--blue-light-5);
}
.err.checkbox:hover,
.err.checkbox:hover::before,
label:hover .checkbox.err,
label:hover .err.checkbox::before {
  background-color: var(--red-light-5);
}
.dot {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 0 2px white, 0 0 0 3px var(--tertiary);
  transition: all 300ms;
}
label:hover .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--blue-light-6);
  box-shadow: 0 0 0 2px var(--blue-light-6), 0 0 0 3px var(--secondary),
    0 0 0 6px var(--blue-light-6);
}
.dot.checked {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--secondary);
  box-shadow: 0 0 0 2px var(--blue-light-6), 0 0 0 3px var(--secondary);
}
label:hover .dot.checked {
  background-color: var(--secondary);
  box-shadow: 0 0 0 2px var(--blue-light-6), 0 0 0 3px var(--secondary),
    0 0 0 6px var(--blue-light-6);
}
label:hover .dot.disabled,
.dot.disabled {
  background-color: white;
  box-shadow: 0 0 0 2px white, 0 0 0 3px var(--dark-8);
}
label:hover .dot.checked.disabled,
.dot.checked.disabled {
  background-color: var(--dark-8);
  box-shadow: 0 0 0 2px white, 0 0 0 3px var(--dark-8);
}
label {
  transition: all var(--duration);
}
input {
  text-overflow: ellipsis;
}
.input {
  display: block;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid var(--stroke);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--secondary);
  width: 100%;
  transition: all var(--duration);
}
.input:hover {
  cursor: pointer;
  border-color: var(--dark-6);
}
.input::placeholder {
  color: var(--dark-6);
}
.input.err {
  color: var(--red);
  border-color: var(--red);
}
.input.err::placeholder {
  color: var(--red);
}
textarea.input {
  resize: none;
  padding: 12px;
  overflow: hidden;
}
textarea.input::placeholder {
  margin: 0 -2px;
}
.privacy-link-text {
  color: var(--secondary);
  display: block;
  margin-left: 8px;
}
.privacy-link-text.err {
  color: var(--red);
}

.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 1;
}
.hidden {
  display: none;
}
.grid {
  display: grid;
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}
.gap-12 {
  gap: 12px;
}
.gap-16 {
  gap: 16px;
}
.gap-20 {
  gap: 20px;
}
.gap-90 {
  gap: 90px;
}
.gap-100 {
  gap: 100px;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.min-w-full {
  min-width: 100%;
}
.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}
.w-fit {
  width: fit-content;
}
.h-full {
  height: 100%;
}
.max-w-408 {
  max-width: 408px;
}
.max-w-468 {
  max-width: 468px;
}
.max-w-488 {
  max-width: 488px;
}
.max-w-510 {
  max-width: 510px;
}
.max-w-700 {
  max-width: 700px;
}
.max-w-43p {
  max-width: 43%;
}
.mt-2 {
  margin-top: 2px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-28 {
  margin-top: 28px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-36 {
  margin-top: 36px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-56 {
  margin-top: 56px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-110 {
  margin-top: 110px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-128 {
  margin-top: 128px;
}
.mx-auto {
  margin: 0 auto;
}
.mr-auto {
  margin-right: auto;
}
.mr-12 {
  margin-right: 12px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-auto {
  margin-bottom: auto;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mx-10 {
  margin: 0 10px;
}
.mx-42 {
  margin: 0 42px;
}
.py-120 {
  padding: 120px 0;
}
.pt-24 {
  padding-top: 24px;
}
.pt-40 {
  padding-top: 40px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-56 {
  padding-bottom: 56px;
}
.pb-126 {
  padding-bottom: 20px;
}
.pl-12 {
  padding-left: 12px;
}
.rounded-full {
  border-radius: 100%;
}
.rounded-12 {
  border-radius: 12px;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.chip {
  background-color: var(--white);
  padding: 4px 12px;
  border-radius: 16px;
}
.dropdown-list {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 20px 0px rgba(45, 104, 248, 0.1);
}
.visibility-hidden {
  visibility: hidden;
}
.uppercase {
  text-transform: uppercase;
}
.hero {
  margin: 76px auto 0;
}
.text-center {
  text-align: center;
}
.icon-12 {
  width: 12px;
  height: 12px;
}
.icon-16 {
  min-width: 16px;
  height: 16px;
  aspect-ratio: 1 / 1;
}
.icon-20 {
  min-width: 20px;
  height: 20px;
  aspect-ratio: 1 / 1;
}
.icon-24 {
  min-width: 24px;
  height: 24px;
  aspect-ratio: 1 / 1;
}
.icon-32 {
  min-width: 32px;
  height: 32px;
  aspect-ratio: 1 / 1;
}
.icon-44 {
  min-width: 44px;
  height: 44px;
  aspect-ratio: 1 / 1;
}
.icon-50 {
  min-width: 50px;
  height: 50px;
  aspect-ratio: 1 / 1;
}
.icon-container {
  display: flex;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--white);
  opacity: 0.8;
  color: var(--tertiary);
  transition: all var(--duration);
}
.icon-container > svg {
  width: 20px;
  height: 20px;
}
.card-bother.active > .icon-container {
  color: var(--primary);
  background-color: var(--blue-light-6);
}
.horizontal-line {
  width: 100%;
  min-height: 1px;
  background-color: var(--stroke);
}

.dropdown-icon {
  transition: all var(--duration);
}
.language-dropdown {
  position: relative;
  display: flex;
  margin-right: 12px;
}
.language-outline-color-transparent {
  --language-outline-color: transparent;
}
#language-dropdown-btn:hover .language-outline-color-transparent {
  --language-outline-color: var(--blue-light-5);
}
.language-outline-color-blue-light-5 {
  --language-outline-color: var(--blue-light-5);
}
.language-border-8 {
  border-radius: 50%;
  outline: 4px solid var(--language-outline-color);
  transition: all var(--duration);
}
.language-image-container {
  display: flex;
  gap: 8px;
  width: fit-content;
  padding: 4px;
  border-radius: 30px;
  background-color: var(--white);
  transition: all var(--duration);
}
.language-image-container.expanded {
  opacity: 0;
}
.language-image-container a {
  display: flex;
}
.language-image {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 16px;
  transition: all var(--duration);
}
.language-image.active,
.language-image:hover {
  background: var(--white);
  box-shadow: 0px 1px 4px 0px rgba(45, 104, 248, 0.54);
}
.dropdown-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  background-color: var(--white);
  width: 100%;
  text-transform: uppercase;
  transition: all var(--duration);
}
.dropdown-button:hover {
  background-color: var(--blue-light-5);
  cursor: pointer;
}
#selected-language {
  display: flex;
  align-items: center;
  gap: 12px;
  text-transform: uppercase;
}
#selected-language:hover {
  cursor: pointer;
}

.dropdown-arrow {
  margin-left: 10px;
  transition: transform 0.3s;
}

#language-dropdown-menu {
  position: absolute;
  left: -16px;
  background-color: var(--white);
  overflow: hidden;
  border-radius: 8px;
  list-style-type: none;
  margin: 0;
  z-index: 1000;
  transition: all var(--duration);
  pointer-events: none;
  opacity: 0;
}
#language-dropdown-menu.show {
  opacity: 1;
  pointer-events: all;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  margin-top: 86px;
}
.marquee-content {
  display: inline-block;
  white-space: nowrap;
}
.rounded-item {
  display: inline-flex;
  gap: 8px;
  height: fit-content;
  padding: 8px 20px;
  border-radius: 8px;
  background-color: var(--blue-light-7);
  color: var(--primary);
  position: relative;
  z-index: 1;
  font-size: 14px;
  line-height: 22px;
}

.yel {
  --custom-c: var(--secondary);
  --custom-bgc: var(--yellow);
}
.bl {
  --custom-c: var(--white);
  --custom-bgc: var(--primary);
}

.custom-rounded-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--custom-bgc);
  color: var(--custom-c);
  z-index: 1;
}

.pulse-container {
  width: 440px;
  height: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.pulse {
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  opacity: 0;
  animation: pulseGrow 4s linear infinite, colorChange 4s ease-in-out infinite;
}

.pulse:nth-child(1) {
  animation-delay: 0s;
}
.pulse:nth-child(2) {
  animation-delay: 1s;
}
.pulse:nth-child(3) {
  animation-delay: 2s;
}
.pulse:nth-child(4) {
  animation-delay: 3s;
}
.pulse:nth-child(5) {
  animation-delay: 4s;
}

@keyframes pulseGrow {
  0% {
    width: 0;
    height: 0;
    border-width: 1px;
    opacity: 1;
  }
  80% {
    width: 350px;
    height: 350px;
    border-width: 1px;
    opacity: 0.8;
  }
  100% {
    width: 440px;
    height: 440px;
    border-width: 1px;
    opacity: 0;
  }
}

@keyframes colorChange {
  0% {
    border-color: var(--purple-light-2);
  }
  50% {
    border-color: var(--purple-light-3);
  }
  80% {
    border-color: var(--purple-light-3);
  }
  100% {
    border-color: var(--purple-light-4);
  }
}

@media (min-width: 1024px) {
  .navigation {
    margin: 0;
    position: absolute;
    right: 50%;
    transform: translate(50%, 0);
  }
}

.contact-us-content {
  display: flex;
  justify-content: space-between;
}
.footer-content {
  display: flex;
  gap: 60px;
}
.footer-right-side {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  max-width: 660px;
  margin-left: auto;
}
.footer-bottom {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}
.pointer-events-none {
  pointer-events: none;
}
.underline {
  text-decoration: underline;
}

/* #region focus */
.prices-card:nth-of-type(even) > .outlined-button-with-icon:focus {
  border-color: var(--blue-dark);
  background-color: var(--blue-dark);
}
.contained-button-with-icon:focus {
  color: var(--primary);
  border-color: var(--yellow);
  background-color: var(--yellow);
}
.contained-button-with-icon:focus > .contained-button-icon {
  background-color: var(--primary);
}
.contained-button-with-icon:focus > .contained-button-icon > svg > .stroke {
  stroke: var(--white);
}
.outlined-button-with-icon:focus {
  color: var(--white);
  border-color: var(--primary);
  background-color: var(--primary);
}
.outlined-button-with-icon:focus > .outlined-button-icon {
  background-color: var(--yellow);
}
.outlined-button-with-icon:focus
  > .outlined-button-icon
  > svg
  > .stroke-outlined {
  stroke: var(--primary);
}
.contained-primary:focus {
  background-color: var(--blue-dark);
}
.outlined-primary:focus {
  color: var(--white);
  border-color: var(--blue-light-2);
  background-color: var(--blue-light-2);
}
.button-text:focus {
  color: var(--secondary);
  border: none;
  background: transparent;
  transition: all var(--duration);
}
.input:focus {
  outline: none;
  border-color: var(--secondary);
}
.dropdown-button:focus {
  background-color: var(--blue-light-2);
}
/* #endregion */

.alert {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 99999;

  padding: 16px;
  display: flex;
  gap: 12px;
  width: fit-content;
  border-radius: 8px;

  background-color: var(--green-light-5);
  color: var(--secondary);

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;

  pointer-events: none;
  transition: all var(--duration);
  opacity: 0;
  transform: translateX(100%);
}
.alert.visible {
  opacity: 1;
  transform: translateX(0);
}

.section-impressum {
  color: var(--secondary);
  padding: 80px 0 120px;
}
