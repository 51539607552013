@media (max-width: 1200px) {
  .max-w-810 {
    --max-w-product-head-image: 100%;
  }
  .max-w-1100 {
    --max-w-product-head-image: 1100px;
  }
  .max-w-1510 {
    --max-w-product-head-image: 1510px;
  }
  .side-right .blur-ball.right-bottom {
    display: none;
  }
  .side-right .blur-ball.center-center {
    top: 60%;
  }
  .side-right {
    margin-left: 30px;
  }
  .prices-card > ul > li {
    margin: 0px 0px 8px 12px;
  }
  .section-question img {
    max-width: 324px;
    max-height: 238px;
  }
  .prices-extra {
    margin-top: 8px;
  }
  .section-innovate-content img {
    max-width: 50%;
  }
  .prdct {
    padding: 0 30px;
  }
  .product-section-description-translate-item {
    padding-top: 120px;
  }
  .product-images {
    position: sticky;
    top: 25%;
    bottom: 25%;
  }
  .prdct.before-gradient-left-to-right::before {
    display: none;
  }
  .section-innovate {
    margin-top: 100px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .section-innovate-head {
    max-width: 100%;
    margin-top: 60px;
  }
  .tasks-images {
    max-width: 500px;
    justify-content: center;
  }
  .tasks-images .i.active {
    transform: scale(1);
  }
  .center-center {
    left: 0%;
    top: 50%;
  }
  .product-button-list {
    display: flex;
  }
  .header {
    margin: 0;
    border-radius: 0;
    padding: 0 30px;
  }
  .product-section-description {
    width: 50%;
  }
  .navigation {
    display: none;
  }

  .section-review-card {
    max-width: 230px;
  }
  .section-review-card:nth-child(1) {
    display: none;
  }
  .section-review-card:nth-child(2) {
    right: 100%;
    bottom: 90%;
    transform: translate(50%, 50%);
  }
  .section-review-card:nth-child(3) {
    right: 100%;
    top: 100%;
    left: auto;
    transform: translate(24px, -90%);
  }
  .section-review-card:nth-child(3) > .relative > .review-quote {
    z-index: -1;
    bottom: 110%;
    transform: rotate(10deg);
    width: 250px;
  }
  .section-review-card:nth-child(3):hover > .relative > .review-quote {
    bottom: 110%;
    right: 14%;
    transform: rotate(0deg);
  }
  .section-review-card:nth-child(2) > .relative > .review-quote,
  .about-us-review-card:nth-child(1) > .relative > .review-quote {
    top: -80%;
    right: 0%;
    width: 250px;
  }
  .section-reviews .section-bother-pulse.rvws {
    margin-left: auto;
  }
  .opportunities-button {
    white-space: nowrap;
  }
  .section-about-us {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }
  .section-about-us .side-left {
    padding: 0;
  }
  .side-left {
    padding: 0 30px;
    margin-right: 0;
  }
  .side-right {
    margin-top: 0;
    margin-left: 0px;
  }
  .product-images-container {
    padding-bottom: 120px;
  }
  .product-images-container::after,
  .product-images-container::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 160px;
    z-index: 100;
  }
  .product-images-container::before {
    background: linear-gradient(
      90deg,
      rgba(239, 242, 255, 0) 45%,
      rgba(244, 246, 255, 0.7) 70.15%,
      var(--blue-light-7) 91.71%
    );
    transform: rotate(180deg);
    left: 0;
  }
  .product-images-container::after {
    background: linear-gradient(
      90deg,
      rgba(239, 242, 255, 0) 45%,
      rgba(244, 246, 255, 0.7) 70.15%,
      var(--blue-light-7) 91.71%
    );
    right: 0;
  }
  .prices-description-text {
    margin-top: 8px;
    color: var(--tertiary);
    text-align: center;
  }
  .section-reviews {
    margin: 60px 0 120px;
  }
  .cookie-content {
    padding: 24px 0px;
    flex-direction: column;
    align-items: center;
  }
  .cookie-actions {
    margin-top: 24px;
    gap: 16px;
  }
  .ml-auto-xs-lg {
    margin-left: auto;
  }
  .menu-navigation {
    padding: var(--header-height) 0px 24px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transform: translateY(-100%);
    opacity: 0;
    transition: all var(--duration) ease-in-out;
    z-index: 1001;
  }
  .menu-navigation.expanded {
    pointer-events: all;
    opacity: 1;
    background-color: var(--white);
    transform: translateY(0%);
  }
  .menu-navigation-list {
    overflow: scroll;
    padding: 40px 30px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }
  .menu-navigation-list::-webkit-scrollbar {
    display: none;
  }
  .menu-navigation-link {
    width: fit-content;
    color: var(--secondary);
    transition: all var(--duration);
  }
  .menu-navigation-link.active,
  .menu-navigation-link:hover {
    cursor: pointer;
    color: var(--primary);
  }
  .menu-navigation-bottom {
    margin-top: auto;
  }
  .menu-accordion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .menu-accordion > svg {
    stroke-width: 1.5px;
    color: var(--secondary);
    transition: all var(--duration);
  }
  .menu-accordion.active > svg {
    color: var(--primary);
  }
  .menu-buttons {
    display: none;
  }
  .menu-accordion.active > svg {
    transform: rotate(-180deg);
    transition: all var(--duration);
  }
  #language-dropdown-menu.desktop {
    top: 130%;
  }
  #language-dropdown-menu:not(.desktop) {
    bottom: 130%;
  }
  .contact-us-content {
    flex-direction: column;
    align-items: center;
    padding: 80px 30px 136px;
    gap: 20px;
    margin: 0 auto;
  }
  .footer {
    width: calc(100% - 60px);
    padding: 40px 0 80px;
  }
  .section-terms {
    padding: 0 30px;
    margin: 60px auto 120px;
  }
  .button-cookie {
    left: 30px;
    bottom: 30px;
    position: sticky;
  }
  .contact-form-about {
    width: clamp(200px, 100%, 526px);
    flex-direction: column;
    margin-top: 24px;
  }
  .contact-form-card {
    padding: 32px;
    width: clamp(200px, 100%, 526px);
  }
  .contact-f-image {
    display: flex;
    max-width: 330px;
    margin-top: 0;
    align-self: flex-end;
    transform: translate(0, -4%);
  }
  .menu-product-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: var(--secondary);
    margin: 0 0 12px 16px;
  }
  .moving-image > img {
    width: 250px;
    height: auto;
    aspect-ratio: 1 / 1;
    margin: -2px;
  }
  .hamburger-button {
    width: 24px;
    height: 24px;
  }
  .line {
    left: 2px;
    height: 1.5px;
    width: calc(100% - 4px);
  }
  .line1 {
    top: 4px;
  }
  .line2 {
    top: 50%;
    transform: translateY(-50%);
  }
  .line3 {
    bottom: 4px;
  }
  .open .line1 {
    transform: translateY(7.25px) rotate(45deg);
  }
  .open .line2 {
    opacity: 0;
  }
  .open .line3 {
    transform: translateY(-7.25px) rotate(-45deg);
  }
  .settings-actions {
    gap: 24px;
    margin-top: 28px;
  }
  .modal {
    padding: 32px;
  }
  .modal-bg.m-cookies.expanded {
    padding: 16px;
  }
  .h2-about {
    margin-top: 120px;
    color: var(--secondary);
  }
  .footer-links {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .about-us-p {
    margin-top: 120px;
    font-size: 16px;
    line-height: 22px;
    color: var(--tertiary);
  }

  .about-icon-1 {
    top: -40px;
    right: 0;
    --rotate: 15deg;
  }
  .about-icon-2 {
    top: 30px;
    right: 100px;
    --rotate: 10deg;
  }
  .about-icon-3 {
    top: 55%;
    right: -30px;
    --rotate: 20deg;
  }
  .about-icon-4 {
    top: 50%;
    right: 100%;
    --rotate: -10deg;
  }
  .about-icon-5 {
    top: 95%;
    right: 90%;
    --rotate: 15deg;
  }
  .about-icon-6 {
    bottom: -50px;
    left: -80px;
    --rotate: -15deg;
  }
  .product-section {
    gap: 60px;
    padding: 0 30px;
    justify-content: space-between;
  }
  .product-section-image {
    max-width: 540px;
    margin: auto;
  }
  .product-vacancies {
    display: block;
  }
  .marquee-box {
    display: none;
  }
  .ticker-box-service {
    display: flex;
    width: 100%;
    margin-top: 40px;
  }
  .ticker-box-service .moving-image {
    position: static;
    width: fit-content;
    gap: 20px;
  }
  .letter-spacing-right-to-left.a-80 {
    margin-top: 80px;
  }
  .letter-spacing-right-to-left.a-32 {
    margin-top: 32px;
  }
  .tags {
    margin-top: 16px;
    display: flex;
    gap: 8px;
  }
  .request-button {
    margin-top: 40px;
  }
  .request-modal-button {
    margin-top: 24px;
  }
  .service-bottom {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 100px 30px 80px;
    align-items: center;
  }
  .modal {
    padding: 32px;
  }
  .product-section-description-item-image {
    display: none;
  }
  :not(.section-reviews) .section-bother-pulse.rvws {
    justify-content: flex-end;
    margin-right: 170px;
  }
  .section-bother-pulse.rvws .absolute-center {
    left: calc(100% - 75px);
  }
  .product-image-card {
    max-width: 235px;
    left: 286px;
    top: 84px;
  }
  .before-gradient-left-to-right::before {
    content: "";
    position: absolute;
    width: 200px;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(
      270deg,
      rgba(225, 232, 255, 0) 5.5%,
      var(--blue-light-7) 100%
    );
  }
  .prices-card-list-2 {
    flex-wrap: wrap;
    padding: 0 30px;
    justify-content: flex-start;
  }
  .prices-card {
    max-width: 50%;
    flex-basis: calc(50% - 10px);
    padding: 24px;
  }
  .prices-card-list-2-join {
    max-width: 50%;
    flex-basis: calc(50% - 10px);

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px 0;
  }
  .prices-join-card {
    display: none;
  }
  .prices-sparcles {
    margin-bottom: 12px;
  }
  .section-prices-page-content .text-center {
    text-align: start;
  }
  .section-prices-page-content {
    padding: 80px 30px 100px;
  }
  .prices-table-row {
    grid-template-columns: 3fr repeat(3, minmax(124px, 1fr));
  }
  .prices-table-cell-accordion-item {
    flex-direction: row-reverse;
    justify-content: start;
    align-items: start;
  }
  :not(.accordion).prices-table-cell {
    text-wrap: nowrap;
  }
  .prices-table-cell-accordion-item.active > svg {
    transform: rotate(180deg);
  }
  .icon-20 {
    min-width: 20px;
    min-height: 20px;
  }
  .icon-24 {
    min-width: 24px;
    min-height: 24px;
  }
  .section-prices-page-gradient-2,
  .section-prices-page-gradient {
    display: none;
  }
  .prices-options-text {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--secondary);
  }
  .opportunities-content {
    flex-direction: column-reverse;
    padding: 0 30px;
    margin: 40px auto 0;
  }
  .section.section-bother {
    padding-left: 30px;
    padding-right: 30px;
  }
  .card-bother-list {
    grid-template-columns: repeat(2, minmax(100px, 150px));
    grid-template-rows: repeat(3, 1fr);
    gap: 12px;
  }
  .section-bother .blur-ball.absolute-center {
    left: 50%;
    top: 0;
  }
  .section-bother-pulse:not(.rvws) {
    margin-top: 0;

    position: absolute;
    right: 50%;
    transform: translateX(50%);
  }
  .card-bother-container .ticker-box {
    padding: 16px 0;
  }
  .ticker-item .card-bother {
    max-width: 140px;
    min-height: 130px;
    text-wrap: wrap;
    flex-grow: 1;
  }
  .custom-rounded-item.bl {
    overflow: hidden;
  }
  .card-bother-list {
    grid-template-columns: repeat(2, minmax(100px, 150px));
    grid-template-rows: repeat(3, 1fr);
    gap: 12px;
  }
  .section-target-card {
    width: 250px;
    flex-direction: column;
    text-wrap: wrap;
    height: 100%;
    gap: 12px;
    padding: 16px;
    margin-right: 12px;
  }
  .section-integrations {
    padding: 80px 30px 40px;
  }
  .opportunities-button:first-of-type {
    margin-left: 184px;
  }
  .top-right-2 {
    top: 120px;
    right: 60%;
  }
  .tasks-head .body-large {
    font-size: 16px;
    line-height: 22px;
  }
  .prices-description {
    font-size: 16px;
    line-height: 24px;
  }
  .list-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .section-platform {
    padding: 0 30px;
  }
  .privacy-link-text {
    font-size: 12px;
    line-height: 20px;
  }
  .left-center {
    --size: 290px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .contact-form-image {
    margin-top: 60px;
    transform: translate(-10%, 0);
  }
}

@media (max-width: 768px) {
  .max-w-810 {
    --max-w-product-head-image: 810px;
  }
  .max-w-1100 {
    --max-w-product-head-image: 1100px;
  }
  .max-w-1510 {
    --max-w-product-head-image: 1510px;
  }
  .max-w-43p {
    max-width: unset;
  }
  .prices-extra {
    margin-top: 20px;
  }
  .moving-image > .description > .body-medium {
    font-size: 12px;
    line-height: 20px;
  }
  .moving-image > .description > .body-small {
    font-size: 10px;
    line-height: 16px;
  }
  .contact-us-content {
    flex-direction: column;
    align-items: center;
    padding: 80px 30px 136px;
  }
  .section-bother {
    flex-direction: column;
  }
  .section-innovate-list {
    width: 100%;
  }
  .product-section-description-translate-item {
    padding-top: 60px;
  }
  .card-bother-textscroll {
    margin-top: 72px;
  }
  .opportunities-content {
    padding: 0 16px;
  }
  .opportunities-image-container {
    max-width: 100%;
  }
  .prices-card-list-2 {
    padding: 0 16px;
  }
  .section-bother .blur-ball.absolute-center {
    top: 0;
  }
  .section-bother-pulse:not(.rvws) {
    margin-top: 0;

    position: absolute;
    right: 50%;
    transform: translateX(50%);
  }
  .section.section-bother {
    flex-direction: column;
    gap: 48px;
  }
  .section.section-bother .section-bother-pulse:not(.rvws) {
    position: relative;
    transform: translateX(0);
    right: 0;
    order: -1;
  }
  .products-expanded-list {
    display: none;
  }
  .h-b {
    display: block;
  }
  .footer-content {
    flex-direction: column;
  }
  .footer-right-side {
    gap: 80px;
    margin: 0;
  }
  .button-cookie {
    left: 30px;
    bottom: 30px;
  }
  .section-innovate {
    margin-top: 80px;
  }
  .contact-f-image {
    max-width: 242px;
  }
  form .body-small,
  .input {
    font-size: 14px;
    line-height: 22px;
  }
  .section-about-us .h2-about {
    font-size: 20px;
    line-height: 26px;
  }
  .h2-about {
    margin-top: 60px;
  }
  .about-us-p {
    margin-top: 80px;
  }
  .section-review-card {
    padding: 20px;
  }
  .section-review-card:nth-child(1) {
    display: none;
  }
  .moving-image > img {
    width: 180px;
    height: 180px;
    aspect-ratio: 1 / 1;
    margin: -2px;
  }
  .section-review-card:nth-child(2),
  .about-us-review-card:nth-child(1) {
    right: 40%;
    bottom: 120%;
    width: 250px;
  }
  .about-us-review-card {
    padding: 16px;
    max-width: 214px;
  }
  .review-quote {
    padding: 12px;
  }
  .section-review-card:nth-child(2) > .relative > .review-quote,
  .about-us-review-card:nth-child(1) > .relative > .review-quote {
    top: -64%;
    right: 0%;
    width: 250px;
  }
  .section-review-card:nth-child(3) {
    top: 140%;
    right: -10%;
  }
  .pulse-container {
    width: 320px;
    height: 320px;
  }
  .blur-size-460,
  .blur-size-600 {
    --size: 300px;
  }
  .blur-size-400 {
    --size: 200px;
  }
  .center-center {
    top: 67%;
  }
  .about-icon-1 {
    top: 70%;
    right: 37%;
    --rotate: 15deg;
  }
  .product-section-description {
    width: 100%;
  }
  .about-icon-2 {
    top: 100%;
    right: 5%;
    --rotate: 10deg;
  }
  .about-icon-3 {
    top: 30%;
    right: 0%;
    --rotate: 20deg;
  }
  .about-icon-4 {
    top: 25%;
    right: 80%;
    --rotate: -10deg;
  }
  .about-icon-5 {
    top: 85%;
    right: 90%;
    --rotate: 15deg;
  }
  .about-icon-6 {
    display: none;
  }
  @keyframes pulseGrow {
    0% {
      width: 0;
      height: 0;
      border-width: 1px;
      opacity: 1;
    }
    80% {
      width: 260px;
      height: 260px;
      border-width: 1px;
      opacity: 0.8;
    }
    100% {
      width: 320px;
      height: 320px;
      border-width: 1px;
      opacity: 0;
    }
  }
  .section-reviews {
    min-height: 480px;
    align-items: flex-end;
    margin: 60px 0 390px;
  }
  .product-section {
    margin-top: 0;
    gap: 20px;
  }
  .product-section-image {
    max-width: 360px;
  }
  .tags {
    flex-wrap: wrap;
    margin-top: 12px;
  }
  .product-images {
    flex-basis: 100%;
  }
  .service-bottom {
    flex-direction: column;
    padding: 36px 16px 80px;
    background: linear-gradient(
      180deg,
      #e1e8ff 61.33%,
      var(--blue-light-7) 100%
    );
  }
  .product-section-description-item {
    gap: 40px;
    justify-content: flex-start;
  }
  .product-section-description-item-image {
    display: none;
  }
  .letter-spacing-right-to-left.a-80,
  .letter-spacing-right-to-left.a-32 {
    margin-top: 0;
  }
  :not(.section-reviews) .section-bother-pulse.rvws {
    justify-content: center;
    margin-right: 0px;
  }
  .absolute-center {
    left: 50%;
  }
  .product-button-list {
    display: none;
  }
  .cookie-container a {
    text-decoration: underline;
  }
  .section-prices-page-gradient-2 {
    display: block;
    position: absolute;
    width: 240px;
    height: auto;
    z-index: -1;
    top: 4.5%;
    right: 0;
  }
  .head {
    margin-top: 140px;
  }
  .prices-card {
    padding: 20px;
  }
  .footer-gradient {
    bottom: auto;
    top: 0;
    right: 0;
  }
  .section-platform {
    padding-left: 16px;
    overflow: hidden;
    border-top-left-radius: 8px;
  }
  .section-question {
    padding: 0 16px;
  }
  .section-impressum {
    padding: 60px 30px 120px;
  }
  .hero {
    text-align: start;
    padding: 0 30px;
    margin: 28px 0 0;
  }
  .hero-buttons {
    padding: 0 30px;
  }
  .faq-container {
    padding: 0 30px;
  }
  .tabs {
    margin: 28px auto 40px;
  }
  .tab {
    max-width: 150px;
    text-wrap: nowrap;
    text-align: center;
  }
  .section-reviews .section-bother-pulse.rvws {
    margin-left: unset;
  }
  .question-dot {
    width: 8px;
    height: 8px;
  }
  .section.section-bother,
  .section.section-tasks,
  .section.section-question {
    padding-left: 16px;
    padding-right: 16px;
  }
  .section-innovate-head {
    margin-bottom: 28px;
  }
  .section-innovate-content {
    align-items: center;
    gap: 40px;
  }
  .section-target-list {
    margin-top: 28px;
    grid-template-columns: 1fr;
  }
  .card-innovate {
    padding: 16px;
  }
  .section-integrations-content {
    flex-direction: column;
  }
  .section-integrations {
    padding: 0 16px 60px;
  }
  .integrations-cards-list {
    margin: 60px auto 0;
    gap: 64px;
  }
  .opportunities-description {
    margin: 40px 0 0;
  }
  .section-target {
    padding: 40px 0px;
  }

  .section-target-card p:last-of-type {
    min-height: 4rem;
  }
  .section-target .text-center {
    text-align: start;
    padding: 0 16px;
  }
  .scroll-to-section .section-reviews {
    margin: 0;
  }
  .section-target .after-gradient-right-to-left::after {
    width: 150px;
  }
  .section-bother-pulse.rvws .absolute-center {
    left: 50%;
  }
  .opportunities-button-container {
    padding: 0 16px;
  }
  .section-innovate-footer {
    padding: 32px 0 40px;
  }
  .section-innovate-footer .rounded-item {
    background-color: transparent;
  }
  .home-ticker-container {
    margin-top: 58px;
  }
  .prices-description {
    margin-top: 16px;
  }
  .prices .mt-12:not(.button) {
    margin-top: 4px;
  }
  .prices .mt-12.button {
    margin-top: 24px;
  }
  .modal.modal-prices.prcs,
  .modal.modal-prices {
    max-width: 500px;
  }
  .modal .button.mt-24 {
    margin-top: auto;
  }
  .modal-form {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  .modal-form > div {
    width: 100%;
  }
  .privacy-link-text {
    font-size: 14px;
    line-height: 22px;
  }
  .md-none {
    display: none;
  }
  .innovate-list-card {
    max-width: 100%;
  }
  .section-tasks {
    gap: 28px;
  }
  .product-images-container::after,
  .product-images-container::before {
    width: 80px;
  }
  .before-gradient::before {
    height: 300px;
  }
  .section-integration-slack {
    flex-direction: column-reverse;
  }
  .section.i-s {
    padding: 0 30px;
  }
  .section.i-s .blur-ball {
    --size: 300px;
  }
  .right-center {
    right: 0;
  }
  .left-center-1 {
    bottom: 0;
  }
  .integration-slack-list .integrations-card:nth-of-type(1) {
    align-self: center;
    transform: rotate(6deg);
  }
  .integration-slack-list
    .integrations-card:nth-of-type(2)
    .integrations-card-icon {
    transform: rotate(-33deg) translate(78%, -58%);
  }
  .integration-slack-list
    .integrations-card:nth-of-type(2)
    .integrations-card-text {
    display: none;
  }
  .integration-slack-list .integrations-card:nth-of-type(3) {
    display: none;
  }
  .contact-form-image {
    margin-top: 0px;
    margin-left: auto;
    transform: translate(10%, 0);
  }
  .prices-table-cell-accordion-item {
    font-size: 14px;
  }
  .prices-table-cell.justify-center {
    font-size: 12px;
    line-height: 20px;
  }
  .products-expanded-button {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: var(--tertiary);
  }
  .footer-bottom {
    flex-direction: column-reverse;
  }
  .footer-terms {
    margin-bottom: 12px;
  }
}

@media (max-width: 560px) {
  .max-w-810 {
    --max-w-product-head-image: calc(810px / 1.5);
  }
  .max-w-1100 {
    --max-w-product-head-image: calc(1100px / 1.5);
  }
  .max-w-1510 {
    --max-w-product-head-image: calc(1510px / 1.5);
  }
  .hero-image {
    width: 800px;
  }
  .text-hero {
    text-align: start;
    margin: 0;
    font-size: 28px;
    line-height: 34px;
  }
  .text-hero br {
    display: none;
  }
  .side-left .mt-24 {
    margin-top: 20px;
  }
  .products-expanded-button {
    padding: 4px;
  }
  .prices-extra {
    margin-top: 16px;
  }
  .about-us-review-card:nth-child(2) {
    display: none;
  }
  .section-review-card:nth-child(2) > .relative > .review-quote,
  .about-us-review-card:nth-child(1) > .relative > .review-quote {
    top: -84%;
    right: 0%;
    width: 250px;
  }
  .section-innovate {
    margin-top: 40px;
    padding-right: 16px;
    padding-left: 16px;
  }
  .innovate-list-card {
    padding: 16px;
    border-radius: 8px;
  }
  .section-innovate-content {
    flex-direction: column;
  }
  .modal.modal-prices textarea {
    max-height: 80px;
  }
  .section-question img {
    max-width: 250px;
  }
  .prices-card > ul > li {
    margin: 4px 0 4px 12px;
  }
  .section-integrations-text {
    letter-spacing: 0.4px;
  }
  .request-modal-button {
    margin-top: auto;
  }
  .header {
    padding: 0 16px;
  }
  .card-bother-list {
    display: none;
  }
  .before-gradient-left-to-right::before {
    width: 100px;
  }
  .before-gradient::before {
    background: linear-gradient(
      180deg,
      rgba(239, 242, 255, 0) 45%,
      rgba(239, 242, 255, 0.7) 70.15%,
      var(--blue-light-7) 91.71%
    );
    z-index: 1;
  }
  .product-image-card {
    max-width: calc(235px / 1.5);
    left: calc(256px / 1.275);
    top: calc(100px / 1.45);
  }
  .section-prices-page-content {
    padding: 40px 16px 60px;
  }
  .section-impressum {
    padding: 40px 16px 80px;
  }
  .body-large.switch-text {
    position: static;
    font-size: 16px;
    line-height: 24px;
  }
  .prices-switch {
    margin: 20px 16px 0;
  }
  .prices-card-list-1,
  .prices-card-list-2 {
    margin-top: 0px;
  }
  .integrations-cards-list {
    gap: 32px;
  }
  .prices-description {
    min-height: auto;
  }
  .prices-description-text {
    max-width: 300px;
    text-align: start;
    margin-left: 16px;
  }
  .section-innovate .text-center {
    text-align: start;
  }
  .section-bother {
    margin-bottom: 40px;
  }
  .section-tasks {
    gap: 40px;
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .tasks-head > img {
    min-width: 114px;
  }
  .tasks-images > img {
    max-width: calc(50% - 8px);
    max-height: 100%;
    opacity: 1;
    border-radius: 8px;
  }
  .section-opportunities {
    padding: 40px 16px 0;
  }
  .section-opportunities .text-center {
    text-align: start;
  }
  .opportunities-buttons,
  .opportunities-content {
    display: none;
  }
  .opportunity-image {
    margin-top: 12px;
    width: 100%;
    border-radius: 8px;
  }
  .section-prices-page {
    padding-top: 0;
  }
  .prices-head {
    position: sticky;
    top: var(--header-height);
    padding: 16px 0;
    background-color: var(--blue-light-7);
    z-index: 1;
  }
  .prices-head .text-center {
    text-align: start;
    padding-left: 16px;
  }
  .section-target .ticker-container {
    padding-bottom: 20px;
  }
  #language-dropdown-menu {
    left: -8px;
    top: 130%;
    bottom: auto;
  }
  .language-dropdown.desktop {
    display: inline-flex;
    padding: 4px 8px;
    border-radius: 16px;
    background-color: var(--white);
    margin: 0;
  }
  .language-dropdown.desktop .body-medium {
    font-size: 14px;
    line-height: 22px;
  }
  .h2-about {
    margin-top: 40px;
  }
  .menu-navigation-bottom {
    display: none;
  }
  .h-b {
    display: none;
  }
  .footer {
    padding: 28px 0 40px;
    width: calc(100% - 32px);
  }
  .footer-right-side {
    flex-direction: column;
    gap: 24px;
    margin: 0;
  }
  .footer-bottom {
    white-space: nowrap;
    margin-top: 60px;
  }
  .footer-terms {
    flex-direction: column;
    align-items: start;
    margin-top: 12px;
    gap: 8px;
  }
  .footer-gradient {
    display: none;
  }
  .section-terms {
    padding: 0 16px;
    margin: 40px auto 80px;
  }
  .footer-socials {
    margin-top: 20px;
  }
  .footer-content {
    gap: 40px;
  }
  .footer-links {
    margin-top: 12px;
    gap: 8px;
  }
  .button-cookie {
    left: 16px;
    bottom: 16px;
  }
  .contact-us-content {
    padding: 40px 16px 80px;
    gap: 0;
  }
  .contact-us-content .body-medium {
    font-size: 14px;
    line-height: 22px;
  }
  .contact-form-about {
    width: clamp(200px, 100%, 526px);
  }
  .contact-form-card {
    padding: 16px;
    width: clamp(200px, 100%, 526px);
  }
  .contact-f-image {
    margin-left: 0;
  }
  .menu-buttons {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: var(--white);
    position: sticky;
    bottom: 0;
  }
  .menu-buttons button {
    width: 100%;
  }
  .menu-navigation-list {
    padding: 40px 16px 0;
  }
  .menu-navigation-list .ml-8 {
    margin-left: 0;
  }
  .menu-navigation-list h4 {
    font-size: 18px;
  }
  .menu-navigation {
    padding: var(--header-height) 0px 24px;
  }
  .products-expanded-extra {
    font-weight: 400;
  }
  .hamburger-button {
    width: 20px;
    height: 20px;
  }
  .hamburger-button {
    width: 20px;
    height: 20px;
  }
  .open .line1 {
    transform: translateY(5.25px) rotate(45deg);
  }
  .open .line3 {
    transform: translateY(-5.25px) rotate(-45deg);
  }
  .tasks-images .i {
    max-width: 165px;
  }
  .cookie-container {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }
  .cookie-container h4 {
    font-size: 18px;
  }
  .cookie-container .body-medium {
    font-size: 14px;
    line-height: 22px;
  }
  .cookie-content {
    padding: 24px 16px;
    background-color: var(--white);
  }
  .cookie-actions {
    gap: 8px;
    flex-direction: column-reverse;
  }
  .cookie-actions .button {
    width: 100%;
  }
  .modal {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    height: 100%;
    padding-bottom: 0;
  }
  .settings-actions {
    position: sticky;
    bottom: 0;
    margin-top: auto;
    padding: 24px 0;
    border-top: 1px solid var(--blue-light-6);
    background-color: var(--white);
    flex-direction: column-reverse;
    gap: 8px;
  }
  .setting-divider {
    opacity: 0;
  }
  .modal {
    padding: 16px;
  }
  .modal.modal-cookies {
    padding: 16px 16px 0;
  }
  .mobile-mb-24 {
    margin-bottom: 24px;
  }
  .modal-bg.m-cookies.expanded {
    padding: 0;
  }
  .about-us-p {
    font-size: 14px;
    line-height: 20px;
  }
  .chip.body-small {
    font-size: 12px;
    line-height: 20px;
  }
  .side-left {
    padding: 0 16px;
  }
  .prdct {
    padding: 0 16px;
  }
  .product-section {
    padding: 0 16px;
  }
  .hero {
    padding: 0 16px;
  }
  .product-vacancies.mt-60 {
    margin-top: 28px;
  }
  .product-section-description-translate-item {
    padding-top: 80px;
    min-height: auto;
  }
  .prices-card-list-2-join,
  .prices-card {
    max-width: 100%;
    flex-basis: 100%;
  }
  .prices-card-list-2-join {
    padding: 20px;
    border-radius: 8px;
    background-color: var(--white);
  }
  .prices-card-list-2-join .mt-28 {
    margin-top: 20px;
  }
  .prices-table .body-large {
    font-size: 16px;
    line-height: 24px;
  }
  .section-prices-page-content h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .section-prices-page-gradient-2 {
    top: 3%;
  }
  .head.prices-table-row {
    grid-template-columns: repeat(3, 1fr);
    padding: 4px 16px;
  }
  :not(.accordion).prices-table-cell {
    text-wrap: wrap;
  }
  .prices-table-row {
    margin-left: -16px;
    margin-right: -16px;
    padding: 8px 16px;
    border-radius: 0px;
  }
  :not(.head).prices-table-row {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr auto;
  }
  .prices-table-cell.accordion {
    padding: 0;
  }
  .prices-table-cell:not(.accordion) {
    text-align: start;
    padding: 10px;
  }
  .prices-table-cell {
    text-wrap: wrap;
  }
  .prices-table-cell:nth-of-type(2) {
    grid-column: 1;
  }
  .prices-table-cell:nth-of-type(3) {
    grid-column: 2;
  }
  .prices-table-cell:nth-of-type(4) {
    grid-column: 3;
  }
  .prices-options-text {
    display: none;
  }
  .prices-table-cell {
    grid-column: 1 / -1;
    font-size: 12px;
    line-height: 20px;
  }
  .section-faq {
    padding: 40px 16px 100px;
  }
  .faq-container {
    margin-top: 28px;
    padding: 0 16px;
  }
  .faq-question {
    font-size: 16px;
    line-height: 24px;
  }
  .faq-answer .body-medium {
    font-size: 16px;
    line-height: 22px;
  }
  .section-innovate-content img {
    max-width: 250px;
  }
  .section-innovate-footer .rounded-item {
    padding: 0;
    background-color: transparent;
  }
  .text-md {
    font-size: 16px;
    line-height: 22px;
  }
  .section-opportunities h2 {
    letter-spacing: 0.4px;
  }
  .integrations-card-icon {
    padding: 6px;
  }
  .integrations-card-text {
    padding: 8px;
  }
  .integrations-card-bg {
    height: fit-content;
    margin: auto;
    border-radius: 8px;
  }
  .section-target h2,
  .section-target h2 > * {
    line-height: 30px;
  }
  .section-review-card {
    padding: 16px;
  }
  .review-card-head .body-medium {
    font-size: 14px;
    line-height: 22px;
  }
  .review-card-head .body-small {
    font-size: 12px;
    line-height: 20px;
  }
  .icon-32 {
    width: 40px;
    height: 40px;
  }
  .language-image-container.expanded {
    opacity: unset;
  }
  .ticker-container .rounded-item {
    align-items: unset;
    margin: 80px 0;
    background-color: var(--white);
    box-shadow: 0px 5px 20px 0px rgba(45, 104, 248, 0.1);
  }
  .modal {
    max-height: 100vh;
  }
  .section.i-s {
    padding: 0 16px;
  }
  .integration-slack-head {
    flex-direction: column;
  }
  .integration-slack-head .w-half {
    width: 100%;
  }
  .integration-slack-list {
    margin-top: 40px;
  }
  .integration-slack-list
    .integrations-card:nth-of-type(2)
    .integrations-card-icon {
    transform: rotate(-33deg) translate(-75px, -112px);
  }
  .section.i-s .blur-ball {
    --size: 250px;
  }
  .section-review-card:nth-child(3) {
    display: none;
  }
  .product-images {
    display: none;
  }
  .product-section-description-item-image {
    display: block;
  }
  .xs-none {
    display: none;
  }
  .menu-product-title {
    margin: 0 0 12px 0;
  }
  .section-about-us {
    padding: 0 16px;
  }
  footer .body-medium.text-secondary.weight-500 {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 375px) {
  .text-hero {
    font-size: 28px;
    line-height: 34px;
  }
  .integrations-card-text,
  .prices-save.body-small {
    font-size: 12px;
    line-height: 20px;
  }
  .opportunities-description .body-medium,
  .prices-description .body-small,
  .prices-table-element,
  .section-prices-page-content .body-medium,
  .section-mobile-ticker .body-medium,
  .section-question .body-medium,
  .section-innovate .body-medium {
    font-size: 14px;
    line-height: 20px;
  }
  .button,
  .body-large.switch-text {
    font-size: 14px;
    line-height: 22px;
  }
  .tasks-head > p,
  h2.section-innovate-head,
  h2.section-innovate-head span {
    font-size: 20px;
    line-height: 24px;
  }
  .side-left {
    padding: 0 16px;
  }
  h4.price {
    font-size: 20px;
    margin-top: 4px;
  }
  .icon-32 {
    width: 32px;
    height: 32px;
  }
  .prices-card .body-medium,
  .prices-card-list-2 .body-medium {
    font-size: 16px;
    line-height: 22px;
  }
  .prices-card .button.mt-12 {
    margin-top: 16px;
  }
  .prices-divider {
    margin: 24px 0;
  }
  .side-right {
    margin: 0;
  }
  .privacy-link-text {
    font-size: 12px;
    line-height: 20px;
  }
  .section-target-list {
    margin-top: 20px;
  }
  .section-innovate-list {
    gap: 24px;
    margin-top: 28px;
  }
  .section-bother {
    margin-top: 160px;
  }
  .contact-form-about {
    margin-top: 0;
  }
  .contact-form-image {
    margin-left: unset;
    transform: translate(-10%, 0);
  }
  .product-section {
    padding: 0 16px;
  }
  .prdct {
    padding: 16px;
  }
  .product-image-card {
    margin: 0 16px;
  }
  .product-image-card {
    left: calc(256px / 1.5);
    top: calc(100px / 1.165);
  }
  .faq-answer.active {
    --collapsed-content-mt: 16px;
  }
  .hero-buttons {
    justify-content: flex-start;
    margin-top: 28px;
    padding: 0 16px;
  }
  .hero {
    padding: 0 16px;
  }
  .section-integrations-content {
    padding: 40px 0 60px;
  }
}

@media (max-width: 320px) {
  .prices-description {
    font-size: 14px;
    line-height: 20px;
  }
  .list-title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  h4.price {
    font-size: 18px;
  }
  .tasks-images .i {
    max-width: 140px;
  }
  .faq-question,
  .faq-answer .body-medium,
  .prices-button,
  .prices-extra {
    font-size: 14px;
    line-height: 22px;
  }
  .prices-card .button.mt-12 {
    margin-top: 24px;
  }
  .section-terms .body-small {
    font-size: 12px;
    line-height: 20px;
  }
}

@media (min-width: 1200px) {
  .max-w-810 {
    --max-w-product-head-image: 810px;
  }
  .max-w-1100 {
    --max-w-product-head-image: 1100px;
  }
  .max-w-1510 {
    --max-w-product-head-image: 1510px;
  }
  .contact-form-about {
    margin-top: 24px;
  }
  .section-question img {
    max-width: 324px;
    max-height: 238px;
  }
  .section-innovate-content img {
    max-height: 345px;
  }
  .tasks-images .i.active {
    transform: scale(1.135);
    transition: all var(--duration-md);
  }
  .section-innovate-head {
    margin: 80px auto 36px;
  }
  .product-button-list {
    display: flex;
  }
  .section-prices-page-gradient-2 {
    display: none;
  }
  .letter-spacing-right-to-left.a-80 {
    margin-top: 80px;
  }
  .letter-spacing-right-to-left.a-32 {
    margin-top: 32px;
  }
  .product-vacancies {
    display: grid;
  }
  .cookie-actions {
    gap: 16px;
  }
  .marquee-box {
    width: 600px;
    height: 600px;
  }
  .section-about-us {
    display: grid;
    grid-template-columns: 1fr repeat(2, min(50%, 590px)) 1fr;
    padding-bottom: 120px;
    overflow: hidden;
  }
  .side-left {
    grid-column: 3 / 4;
    margin-right: 10px;
  }
  .side-right {
    grid-column: 4 / 6;
    margin-left: 10px;
  }
  .section-about-us .side-left {
    grid-column: 2 / 3;
    margin-right: 10px;
  }
  .section-about-us .side-right {
    grid-column: 3 / 4;
    margin-left: 10px;
  }
  .section-innovate {
    margin-top: 100px;
  }
  .cookie-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 24px 0px;
  }
  #language-dropdown-menu {
    top: 130%;
  }
  .menu-navigation {
    display: none;
  }
  .h-b {
    display: block;
  }
  .hamburger-button {
    display: none;
  }
  .button-cookie {
    left: 24px;
    bottom: 24px;
    position: sticky;
  }
  .contact-form-about {
    width: clamp(200px, 100%, 488px);
  }
  .contact-form-card {
    padding: 32px;
    width: clamp(200px, 100%, 488px);
  }
  .contact-f-image {
    max-width: 330px;
    margin-top: 60px;
    transform: translate(0, 25%);
  }
  .contact-us-content {
    padding: 80px 0px 136px;
    gap: 90px;
  }
  .settings-actions {
    margin-top: 28px;
    gap: 24px;
  }
  .modal-bg.m-cookies.expanded {
    padding: 32px;
  }
  .section-terms {
    padding: 0 30px;
    margin: 60px auto 120px;
  }
  .footer-links {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .footer {
    padding: 40px 0 80px;
    width: calc(100% - 60px);
  }
  .h2-about {
    margin-top: 120px;
    color: var(--secondary);
  }
  .about-us-p {
    margin-top: 120px;
    font-size: 16px;
    line-height: 22px;
    color: var(--tertiary);
  }

  .about-icon-1 {
    top: -40px;
    right: 0;
    --rotate: 15deg;
  }
  .about-icon-2 {
    top: 30px;
    right: 100px;
    --rotate: 10deg;
  }
  .about-icon-3 {
    top: 55%;
    right: -30px;
    --rotate: 20deg;
  }
  .about-icon-4 {
    top: 50%;
    right: 100%;
    --rotate: -10deg;
  }
  .about-icon-5 {
    top: 95%;
    right: 90%;
    --rotate: 15deg;
  }
  .about-icon-6 {
    bottom: -50px;
    left: -80px;
    --rotate: -15deg;
  }
  .product-section-image {
    max-width: 540px;
  }
  .prices-description-text {
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
    color: var(--tertiary);
    text-align: center;
  }
  .ticker-box-service {
    display: none;
  }
  .tags {
    margin-top: 20px;
    display: flex;
    gap: 8px;
  }
  .request-button {
    margin-top: 56px;
  }
  .request-modal-button {
    margin-top: 24px;
  }
  .product-section {
    margin-top: 120px;
    justify-content: space-between;
  }
  .service-bottom {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 100px 0px 136px;
  }
  .product-section-description-translate-item {
    padding-top: 120px;
  }
  .modal {
    padding: 32px;
  }
  .product-section-description-item-image {
    display: none;
  }
  .product-section-description {
    width: 50%;
  }
  .product-image-card {
    max-width: 235px;
    left: 256px;
    top: 84px;
  }
  .moving-image > img {
    width: 250px;
    height: auto;
    aspect-ratio: 1 / 1;
    margin: -2px;
  }
  .prices-card-list-2-join {
    display: none;
  }
  .prices-card {
    padding: 24px;
  }
  .prdct.before-gradient-left-to-right::before {
    display: none;
  }
  .product-section-description-translate-item {
    min-height: 500px;
  }
  .product-images {
    display: grid;
    position: sticky;
    top: 25%;
    bottom: 25%;
  }
  .prices-card > ul > li {
    margin: 0px 0px 8px 12px;
  }
  .list-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .privacy-link-text {
    font-size: 12px;
    line-height: 20px;
  }
  .prices-button {
    font-size: 16px;
    line-height: 24px;
  }
  .prices-extra {
    margin-top: 8px;
  }
  .contact-form-image {
    margin-top: 60px;
    transform: translate(-10%, 0);
  }
}

@media (min-width: 1440px) {
  .section-about-us {
    grid-template-columns: 1fr 120px repeat(2, min(50%, 590px)) 120px 1fr;
  }
  .side-left {
    grid-column: 3 / 4;
    margin-right: 10px;
  }
  .side-right {
    grid-column: 4 / 6;
    margin-left: 10px;
  }
  .section-about-us .side-left {
    grid-column: 3 / 4;
    margin-right: 10px;
  }
  .section-about-us .side-right {
    grid-column: 4 / 6;
    margin-left: 10px;
  }
  .home-ticker-container.before-gradient-left-to-right::before,
  .home-ticker-container.after-gradient-right-to-left::after {
    width: 100px;
    display: block;
  }
  .home-ticker-container.before-gradient-left-to-right::before {
    background: linear-gradient(
      90deg,
      var(--blue-light-7) 0%,
      rgba(244, 246, 255, 0) 100%
    );
  }
  .home-ticker-container.after-gradient-right-to-left::after {
    background: linear-gradient(
      270deg,
      var(--blue-light-7) 0%,
      rgba(244, 246, 255, 0) 100%
    );
  }
  .button-cookie {
    position: fixed;
  }
}
