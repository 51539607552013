.cookie-container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  backdrop-filter: blur(25px);
  background: rgba(255, 255, 255, 0.60);
  box-shadow: 0px -5px 40px 0px rgba(45, 104, 248, 0.15);
}

.cookie-container.accepted {
  display: none;
}

.cookie-actions {
  display: flex;
  align-items: center;
}

.cookie-setting {
  display: flex;
  align-items: center;
  gap: 16px;
}

.cookie-switch {
  margin-left: auto;
}

.cookie-expand-item {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.cookie-expand-item > svg {
  transition: all var(--duration);
}
.cookie-expand-item.active > svg {
  transform: rotate(180deg);
  transition: all var(--duration);
}

.cookie-text {
  color: var(--tertiary);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  max-height: 0;
  overflow: hidden;
  transition: all var(--duration);
  margin-top: var(--collapsed-content-mt, 0px);
}

.cookie-text.active {
  --collapsed-content-mt: 16px;
}

.setting-divider {
  height: 1px;
  background-color: var(--gray-2);
  width: 100%;
  margin: 16px 0;
}

.settings-actions {
  display: flex;
  align-items: center;
}
